import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container } from 'react-bootstrap';

import { getRelatedContentsByProducts, getPosts, memoizedGetPosts } from '../../../Cms/CmsActions.js';

import { getTranslation } from '../../../Intl/IntlHelper.js';
import PostsSlider from '../../../Cms/components/view/PostsSlider.js';

function ProductRelatedPosts({ page, products, locale, posts = [], titleKeyword = '', hideTitle = false, mode = 'full', excerptSize = 80 }) {
	const [relatedPosts, setRelatedPosts] = useState(posts);

	useEffect(() => {
		setRelatedPosts(getRelatedContentsByProducts(products, posts, locale, page && [page]));
	}, []);

    if(!relatedPosts.length) {
        return null;
    }
    let title = <span className="d-block h2 w-75 mx-auto border-half-pink text-center"><FormattedMessage id="learnMoreButton" defaultMessage="Learn more" /></span>;

    if(products.length === 1) {
        title = <h2 className="border-half-pink text-center"><FormattedMessage id="productPostsAboutTitle" defaultMessage="Learn more about {product}" values={{ product: getTranslation(products[0], 'name', locale) }} /></h2>;
    } else if(products.length > 1 && titleKeyword) {
        title = <h2 className="border-half-pink text-center"><FormattedMessage id="productPostsAboutTitle" defaultMessage="Learn more about {product}" values={{ product: titleKeyword }} /></h2>;
    }

    return (
		<Container className="mb-3 px-2 px-md-0">
            {!hideTitle && title}
            <PostsSlider posts={relatedPosts} mode={mode} excerptSize={excerptSize} />
		</Container>
    );
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
        posts: memoizedGetPosts(store, store.intl.locale, null),
    };
}

ProductRelatedPosts.propTypes = {
    locale: PropTypes.string.isRequired,
	page: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    posts: PropTypes.arrayOf(PropTypes.object),
    titleKeyword: PropTypes.string,
	hideTitle: PropTypes.bool,
    mode: PropTypes.string,
	excerptSize: PropTypes.number,
};

export default connect(mapStateToProps)(ProductRelatedPosts);
