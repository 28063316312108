import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'react-bootstrap';

import { getProductAttributeList, memoizedGetProducts } from '../../ProductActions';
import { getContentBlockItemsByName } from '../../../Cms/CmsActions';
import { getDefaultLanguage } from '../../../Intl/IntlHelper';

import ProductList from './ProductList';
import PackList from '../pack/PackList';
import ProductRelatedPosts from '../view/ProductRelatedPosts';

function ProductsListWrapper({ intl, page, products = [], hideTitle = false, disablePagination = false, productsByPage = 10 }) {
	const categories = getContentBlockItemsByName(page.blocks, 'category').map(cat => cat.text).filter(cat => cat);
	products = products.filter(product => [...getProductAttributeList(product, 'categories', intl.locale), ...getProductAttributeList(product, 'categories', getDefaultLanguage())].some(productCategory => categories.map(cat => `${cat}`.toLowerCase()).includes(productCategory.toLowerCase())));
	return (
		<Fragment>
			<Container className="critical-css-limiter bg-white pt-4 px-2 px-md-0">
				{products.length > 0 && !hideTitle && (
					<h3 className="d-inline-block h1 border-half-danger text-center w-100">
						{page.title}
					</h3>
				)}

				<ProductList
					products={products}
					page={page}
					productsByPage={productsByPage}
					categoryOrder={(getContentBlockItemsByName(page.blocks, 'productsOrder') || []).map(item => item.text)}
					promotions={getContentBlockItemsByName(page.blocks, 'promotion')}
				/>

				<div className="mt-3 pt-3 border-top border-5 border-light">
					<PackList categories={categories} />
				</div>
			</Container>
			<ProductRelatedPosts products={products} titleKeyword={categories.join(', ')} excerptSize={20} />
		</Fragment>
	);
}

ProductsListWrapper.propTypes = {
	intl: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
	hideTitle: PropTypes.bool,
	disablePagination: PropTypes.bool,
	productsByPage: PropTypes.number,
};

function mapStateToProps(store, props) {
    return {
        products: memoizedGetProducts(store, { isActive: true, isPack: false, isVariation: false }),
    };
}

export default connect(mapStateToProps)(injectIntl(ProductsListWrapper));
