import { createSelector } from 'reselect';

import callApi from '../../util/apiCaller.js';

import { setRedirect } from '../App/AppActions.js';

import { defaultLanguage } from '../../../Intl/setup.js';
import { stripTags, truncate } from '../../util/text.js';
import { getLanguagePath } from '../Intl/IntlHelper.js';

export const SET_CONTENTS = 'SET_CONTENTS';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_CONTENT_TRANSLATION = 'SET_CONTENT_TRANSLATION';

export const CMS_BLOG_POSTS_COUNT = false && process.env.NODE_ENV === 'development' ? 3 : 10;

// TODO: Handle fetch unique content without cache !!

export function getCmsTemplateContentsRequest(language = null) {
    return dispatch => {
        return callApi(`cms/template/${language || ''}`).then(res => {
            res.contents && dispatch(setContents(res.contents));
            return res.contents;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function getCmsContentsRequest(type = null, language = defaultLanguage, page = null, size = CMS_BLOG_POSTS_COUNT) {
    return dispatch => {
        let url = 'cms';
        if(type && language) {
            url += `/${type}/${language}/?page=${page || ''}&size=${size}`;
        }
        return callApi(url).then(res => {
            res.contents && dispatch(setContents(res.contents, !type && !page));
            return res.contents;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}
export function searchCmsContentsRequest(search = '') {
    return dispatch => {
        if(search) {
            return callApi(`cms?search=${search}`).then(res => {
                res.contents && dispatch(setContents(res.contents));
                return res.contents;
            }).catch(err => {
                console.error(err);
                return null;
            });
        }
        return Promise.resolve([]);
    };
}

export function getCmsPageRequest(slug, language = defaultLanguage, forceRefresh = false, query = '') {
    return dispatch => dispatch(getCmsRequest(slug || '*', language, '', forceRefresh, query)); // Do not set type because "page" can be page or post
}

export function getCmsPanelRequest(slug, language = defaultLanguage, forceRefresh = false, query = '') {
    return dispatch => dispatch(getCmsRequest(slug, language, 'panel', forceRefresh, query));
}

export function getCmsPostRequest(slug, language = defaultLanguage, forceRefresh = false, query = '') {
    return dispatch => dispatch(getCmsRequest(slug, language, 'post', forceRefresh, query));
}

export function getCmsRequest(slug, language = defaultLanguage, type = '*', forceRefresh = false, query = '') {
    return dispatch => {
        if(slug) {
            return callApi(`cms/${(type || '*').toLowerCase()}/${(language || defaultLanguage).toLowerCase()}/${(slug || '').toLowerCase()}${forceRefresh ? '?refreshCache=1' : (query ? (query.includes('?') ? query : `?${query}`) : '')}`).then(res => {
				if(type === 'panel') {
					res && !res.redirect && dispatch(setContents([
						(res.content || { slug, type, language }),
						...(res.associatedContents || []),
					]));
				} else {
					res && res.content && res.redirect && dispatch(setRedirect(`/${language}/${res.content.slug}`, (res.content.redirections.find(redirection => redirection.path === slug) || {}).statusCode));
					res && res.content && !res.redirect && dispatch(setContents([res.content, ...(res.associatedContents || [])]));
				}
                // res && res.associatedContents && dispatch(setContents(res.associatedContents));
                // res && res.content && res.blocks && dispatch(setBlocks(res.content._id, JSON.parse(res.blocks)));
                return res.content;
            }).catch(err => {
                console.error(err);
                // dispatch(addError(error));
                return null;
            });
        }
        return Promise.resolve(null);
    };
}

export function getCmsByIdRequest(contentId, withTranslations = true) {
    return dispatch => {
        return callApi(`cms/${contentId}?refreshCache=1`).then(res => {
            if(res && res.content) {
                dispatch(setContents([res.content]));
                withTranslations && dispatch(getCmsPageTranslationsRequest(contentId));
            }
            return res && res.content;
        }).catch(err => {
            console.error(err);
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getCmsPageTranslationsRequest(contentId) {
    return dispatch => {
        return callApi(`cms/${contentId}/translations`).then(res => {
            res && res.translations && dispatch(setContentTranslations(contentId, res.translations));
            return res.translations;
        }).catch(err => {
            console.error(err);
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getCmsTranslatedContentRequest(contentId) {
    return dispatch => {
        return callApi(`cms/${contentId}/translate`).then(res => {
            return res.content;
        }).catch(err => {
            console.error(err);
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editCmsContentRequest(content) {
    return dispatch => {
        return callApi('cms/edit', 'post', { content }).then(res => {
            res.content && dispatch(setContent(res.content));
            return res.content;
        }).catch(err => {
            return null;
        });
    };
}

export function removeCmsContentRequest(contentId) {
    return dispatch => {
        return callApi('cms/remove', 'delete', { content: { _id: contentId } }).then(res => {
            res.ok && getCmsContentsRequest();
            return res.ok;
        }).catch(err => {
            return null;
        });
    };
}

export function generateRequest(data) {
    return callApi('cms/generate', 'post', data).then(res => {
        return res;
    }).catch(err => {
        return null;
    });
}

export function getContents(store) {
    return store.cms.data;
}

export function getPages(store, lang = null, model = null) {
    return store.cms.data.filter(content => content.type === 'page' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
}
export const memoizedGetPages = createSelector(
	[
		store => getContents(store),
		(store, lang, model) => lang,
		(store, lang, model) => model,
	],
	(contents, lang = null, model = null) => {
		return contents.filter(content => content.type === 'page' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
	},
);

export function getPanels(store, lang = null, model = null) {
    return store.cms.data.filter(content => content.type === 'panel' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
}
export const memoizedGetPanels = createSelector(
	[
		store => getContents(store),
		(store, lang, model) => lang,
		(store, lang, model) => model,
	],
	(contents, lang = null, model = null) => {
		return contents.filter(content => content.type === 'panel' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
	},
);

export function getPosts(store, lang = null, model = null) {
    return store.cms.data.filter(content => content.type === 'post' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
}
export const memoizedGetPosts = createSelector(
	[
		store => getContents(store),
		(store, lang, model) => lang,
		(store, lang, model) => model,
	],
	(contents, lang = null, model = null) => {
		return contents.filter(content => content.type === 'post' && content._id && (!lang || lang === content.language) && (!model || model === content.model));
	},
);

export function getContent(store, contentId) {
    // console.log('Cms::: getContent', contentId);
    return store.cms.data.find(content => content._id === contentId);
}

export function getPanelBySlugAndLanguage(store, slug, language) {
    return store.cms.data.find(content => content.type === 'panel' && content.slug === slug && content.language === language);
}
export const memoizedGetPanelBySlugAndLanguage = createSelector(
	[
		store => getContents(store),
		(store, slug, language) => slug,
		(store, slug, language) => language,
	],
	(contents, slug, language) => {
		return contents.find(content => content.type === 'panel' && content.slug === slug && content.language === language);
	},
);

export function getPageBySlugAndLanguage(store, slug, language) {
    slug = (slug || '').split('/').filter(part => part).join('/'); // Handle slug like /blog/mon-article
    return store.cms.data.find(content => content.type !== 'panel' && (content.slug.toLowerCase() === (slug || '').toLowerCase() || (content.slug === '*' && !slug) || (content.redirections || []).map(redirection => (redirection.path || '').toLowerCase()).includes((slug || '').toLowerCase())) && content.language === language);
}

export function getContentTranslations(content, contents) {
    const translations = content && content._id
    ? contents.filter(otherContent => (
        ((content || {}).translatedFrom || '').toString() === ((otherContent || {})._id || '').toString()
        || ((content || {})._id || '').toString() === ((otherContent || {}).translatedFrom || '').toString()
        || (((content || {}).translatedFrom || '').toString() && ((content || {}).translatedFrom || '').toString() === ((otherContent || {}).translatedFrom || '').toString())
    ))
    : [];

    if(content && !content.translatedFrom) {
        translations.push(content);
    }
    return translations;
}

export function getCmsPageSeo(page, key) {
    return (page && page.seo && page.seo[key]) || '';
}

export function getCmsPageSeoTitle(page, defaultSuffix = '', defaultTitle = '') {
    let title = '';
    let suffix = defaultSuffix || '';
    let forceSuffix = false;
    if(page) {
        if((page.seo || {}).title) {
            title = page.seo.title;
        } else if(page.title) {
            title = page.title;
        }
        if((page.seo || {}).useCustomTitleSuffix) {
            suffix = page.seo.titleSuffix || '';
            forceSuffix = true;
        }
    }

    if(!title && defaultTitle) {
        title = defaultTitle;
    }

    const needSuffix = suffix && (forceSuffix || title.length < 50);
    return `${title}${needSuffix ? ` | ${suffix}` : ''}`;
}

export function getCmsPageSeoDescription(page, defaultDescription = '') {
    let description = (page.seo || {}).description || '';

    if(!description && page) {
        description = stripTags((page.blocks || []).reduce((text, block) => {
            if(block.type === 'html') {
                return block.items.reduce((itemsText, item) => (item.text ? `${itemsText} ${item.text}` : itemsText), text);
            }
            if(block.type === 'mixed') {
                return block.items.reduce((itemsText, item) => {
                    const htmlsSubNames = block.blocks.filter(sub => sub.type === 'html').reduce((names, sub) => names.concat(sub.name), []);
                    return `${itemsText} ${htmlsSubNames.map(name => (item[name] || []).map(data => data.text || '').join(' ')).join(' ')}`;
                }, text);
            }
            return text;
        }, ''));
    }
    if(!description && defaultDescription) {
        description = stripTags(defaultDescription);
    }
    return description.length > 155 ? `${truncate(description, 160, false)}...` : description;
}

export function getContentOption(content, key) {
    return (content && content.options && content.options[key]) || '';
}

export function getContentBlockByName(contentBlocks, blockName) {
    return (contentBlocks || []).find(existingBlock => existingBlock.name === blockName);
    // if(Array.isArray(contentBlocksOrMixedItems)) {
    // }
    // if(typeof contentBlocksOrMixedItems === 'object') {
    //     return {
    //         name: blockName,
    //         items: contentBlocksOrMixedItems[blockName],
    //     };
    // }
    // return null;
}

export function getContentBlockData(contentBlocks, block) {
    return getContentBlockByName(contentBlocks, block.name) || block;
}

export function getContentBlockItemsByName(contentBlocks, blockName) {
    return ((getContentBlockByName(contentBlocks, blockName) || {}).items || []).sort((itemA, itemB) => itemA.sort - itemB.sort);
}

export function getContentBlockItemByName(contentBlocks, blockName, filters = {}) {
    const items = (getContentBlockItemsByName(contentBlocks, blockName) || [])
		.filter(item => Object.keys(filters || {})
			.every(filterKey => item[filterKey] === filters[filterKey]));
    return items.length ? items[0] : null;
}

export function getContentBlockMixedItems(mixedItem, subItemName) {
    return (mixedItem && mixedItem[subItemName]) || [];
}

export function getContentBlockMixedItem(mixedItem, subItemName) {
    const items = getContentBlockMixedItems(mixedItem, subItemName);
    return items.length ? items[0] : null;
}

export function getContentBlockItemValue(contentBlocks, blockName, key = 'text', filters = {}) {
    return (getContentBlockItemByName(contentBlocks, blockName, filters) || {})[key] || '';
}

export function getRelatedContentsByProduct(product, contents, language, contentsToExclude = []) {
    const productRelatedContents = contents.filter(content => (
		!contentsToExclude.find(toExclude => toExclude._id.toString() === content._id.toString())
		&& content.language === language
		&& getContentBlockItemsByName(content.blocks, 'relatedProducts').map(item => item.text).includes(product._id.toString())
	));
	return productRelatedContents;
	// return getRelatedContentsByCategories(product.categories, contents, language, productRelatedContents);
	// [
		// ...getRelatedContentsByCategories(product.categories, contents, language, productRelatedContents),
	// ];
}

export function getRelatedContentsByProducts(products, contents, language, contentsToExclude = []) {
    const uniquePosts = [];
    products
    .map(product => getRelatedContentsByProduct(product, contents, language, contentsToExclude))
    .reduce((all, posts) => all.concat(...posts), [])
    .forEach(post => {
        if(!uniquePosts.find(existing => existing._id.toString() === post._id.toString())) {
            uniquePosts.push(post);
        }
    });
    return uniquePosts;
}

export function getRelatedContentsByCategories(categories, contents, language, contentsToExclude = []) {
    return contents
        .filter(content => (
            !contentsToExclude.find(toExclude => toExclude._id.toString() === content._id.toString())
            && content.language === language
            && getContentBlockItemsByName(content.blocks, 'tags')
                .map(item => item.text)
                .some(category => categories.map(cat => cat.toLowerCase()).includes(category.toLowerCase()))
        ));
}

export function getCmsInfoFromPath(path) {
	const search = path.includes('?') ? path.split('?').pop() : '';
	const url = (path.includes('?') ? path.split('?').shift() : path).substring(1);
	const languagePath = getLanguagePath(url, url.split('/').shift()).substring(1);
	const [language, ...slugParts] = languagePath.split('/');
	return {
		language,
		slug: slugParts.join('/'),
		search,
	};
}

export function setContents(contents, resetAll = false) {
    return {
        type: SET_CONTENTS,
        contents,
        resetAll,
    };
}

export function setContent(content) {
    return {
        type: SET_CONTENT,
        content,
    };
}

export function setContentTranslations(contentId, translations) {
    return {
        type: SET_CONTENT_TRANSLATION,
        contentId,
        translations,
    };
}
